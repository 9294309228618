const Drinks = [

    {
        id:39,
        name:"Tea",
        price:"1.5",
        mealQuantity:"normal"
    },

     
    {  id:40,
        name:"Coffee",
        price:"1.5",
        mealQuantity:"normal"
    },
   
   
    {
        id:41,
        name:"Bebinca",
        price:"20",
        mealQuantity: "1lb"
    }
    ,
    {
        id:41,
        name:"Bathika",
        price:"15",
        mealQuantity: "1lb"
    }
    ,
    {
        id:41,
        name:"Channa Doce",
        price:"15",
        mealQuantity: "1lb"
    }
    ,
    {
        id:41,
        name:"Channa Doce",
        price:"20",
        mealQuantity: "1lb"
    }
    ,
  
   
    
    
   
    
    

]




export default Drinks;