const snacks = [
 
    {   id:10,
        name:"Beef/Chicken PAN ROLLS",
        price:"27",
        mealQuantity: "(12 pcs) ",
        description:"A savoury crepe or thin pancake with a filling of either minced meat, prawns or vegetables"
    },  
    
    
    {
        id:11,
        name:"FISH CUTLET",
        price:"25",
        mealQuantity: "(12 pcs) ",
        description:"A thick binder combined with a filling, which is breaded and deep-fried, and served as a side dish"
    }
    ,
    {   id:12,
        name:"BEEF CUTLET",
        price:"25",
        mealQuantity: "(12 pcs) ",
        description:"A thick binder combined with a filling, which is breaded and deep-fried, and served as a side dish"
    },
    {
        id:13,
        name:"SANNAS ",
        price:"18",
        mealQuantity: "(12 pcs) ",
        description:"Steamed Goan Rice cake made from rice and  toddy"
    },
    {
        id:14,
        name:"JAGGERY SANNAS",
        price:"20",
        mealQuantity: "(12 pcs) ",
        description:"Steamed Goan Rice cake made from rice, jaggery and  toddy"
    },
    {   id:15,
        name:"Pattice (Puff Pastry)",
        price:"25",
        mealQuantity: "(12 pcs) ",
        description:"Flaky light pastry made from a laminated dough  with stuffed chicken or beef or vegetable"
    },
    
    {  id:16,
        name:"BEEF CROQUETTE",
        price:"25",
        mealQuantity: "(12 pcs) ",
        description:"A thick binder combined with a filling, which is breaded and deep-fried, and served as a side dish"
    },
  
    { id:17,
        name:'Beef/Chicken Potato Chops',
        price: '28',
        mealQuantity: "(12 pcs) ",
        description:"Mashed potato deep fried with  stuffing of your choice beef or chicken"
      },
      { id:18,
        name:'Railway Cutlet ',
        price: '25',
        mealQuantity: "(12 pcs) ",
        description:"A snack munching recipe made by particularly popular with the Indian Railways"
      },
      { id:19,
        name:'Chicken Frankie Wrap ',
        price: '60',
        mealQuantity: "(12 pcs) ",
        description:`Chicken Frankie, often hailed as a culinary delight, is a mouthwatering wrap that's perfect for a quick, hearty meal. Tender, spiced chicken pieces are enveloped in a soft, warm roti, and finished with a medley of fresh vegetables and tangy sauces. Every bite offers a symphony of flavors and textures,
         making it a go-to favorite for street food lovers everywhere.`
      },
      { id:20,
        name:'Choris Pav ',
        price: '36',
        mealQuantity: "(12 pcs) ",
        description:`Choris Pav, a Goan delicacy, is a beloved spicy sausage roll that tantalizes the taste buds. The choris, a pork sausage bursting with bold, aromatic spices, is snugly tucked into a soft pav (bread roll), creating a perfect harmony of flavors and textures. Each bite is a delightful journey through the rich culinary
         traditions of Goa, offering a hearty and satisfying experience that's hard to resist.`
      },
      { id:21,
        name:'Prawn Rissois',
        price: '26',
        mealQuantity: "(12 pcs) ",
        description:`Goan Prawn Rissois are a Portuguese-inspired delicacy that promises an explosion of flavor with each bite. These crispy, golden turnovers are filled with a luscious prawn mixture, seasoned to perfection with a blend of spices, herbs, and creamy goodness. Perfectly paired with a tangy dipping sauce,
         prawn rissois make an irresistible appetizer that leaves you craving more.`
      },
      { id:21,
        name:'Chutney Sandwich',
        price: '18',
        mealQuantity: "(12 pcs) ",
        description:`The chutney sandwich is a delightfully simple yet flavorful snack that packs a punch. Made with slices of soft bread, generously spread with vibrant, tangy chutney, and layered with fresh vegetables, it offers a burst of flavors in every bite. Perfect for a light meal or a tea-time treat,
         this sandwich is a favorite for its refreshing taste.`
      }
 
 
   

]



export default snacks;