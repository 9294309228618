const curries = [

    {  id:31,
        name:"Pork Sorpatel",
        price:"16",
        mealQuantity: "24oz",
        description:"sorpotel, a spicy tangy pork preparation, has Portuguese roots and is traditionally made for Christmas in Goan Catholic households. The fiery curry is generally served with a side of sanna (steamed rice bread), poi (wheat buns), or parboiled rice."
    },
    {  id:32,
        name:"Pork/Chicken Vindaloo",
        price:"16",
        mealQuantity: "24oz",
        description:"A standard element of Goan cuisine derived from the Portuguese carne de vinha d'alhos (literally meat in garlic marinade), a vindaloo is a dish of meat (usually pork) marinated in vinegar and garlic."
    },
    {  id:33,
        name:"Pork Goan Sausage (Choris)",
        price:"25",
        mealQuantity: "1lb",
        description:"The Goa sausage or Choris is a typical reflection of Indo-Portuguese cuisine from Goa"
    },
    {  id:34,
        name:"Goan Choris Style Meat",
        price:"22",
        mealQuantity: "24oz",
        description:"Delicious choris meat that can be fried with some onions"
    },
    {  id:34,
        name:"Goan Sausage Pulav",
        price:"24",
        mealQuantity: "24oz",
        description:"Mix of pulav with sausage meat for the protein"
    },
    {  id:35,
        name:"Pork Roast",
        price:"20",
        mealQuantity: "24oz",
        description:"This is a super moist, perfectly seasoned, traditional Goan tender roast pork recipe"
    },
    {  id:36,
        name:"Beef/Pork Roast",
        price:"20",
        mealQuantity: "24oz",
        description:"This is a super moist, perfectly seasoned, traditional Goan tender roast beef recipe"
    },
    {  id:37,
        name:"Beef Tongue Roast",
        price:"20",
        mealQuantity: "24oz",
        description:"One of the most delicious goan delicacies is the ox tongue, not just the flavour, but the texture and feel of the meat as well leaves one ..."
    },
    {  id:38,
        name:"Bombay Duck Pickle",
        price:"13",
        mealQuantity: "250ml",
        description:"Bombay Duck pickle is a tastiest fish pickle locally known as Bombil, this fish is cleaned and dried for months on end till they become hard."
    },
     
    {  id:19,
        name:"Chicken Cafreal",
        price:"20",
        mealQuantity: "24oz",
        description:"Cooked with a sauce made with fresh cilantro leaves whole spices like clove, pepper, cinnamon , bay leaf and herbs, ginger, garlic, lemon  and chillies, shallow fried meat and then added to the slow cooking - sauce "
    },
   
     {
        id:20,
        name:"Prawn Masala",
        price:"18",
        mealQuantity: "24oz",
        description:"Spicy Indian prawn dish that is mixed with authentic spices, coconut milk, herbs, and tomatoes. It is best paired with plain rice or roti.."
    }
    ,
 
  
    {   id:21,
        name:" Xacuti",
        price:"16",
        mealQuantity: "24oz",
        description:"Complex spices, including white poppy seeds,grated coconut and large dried red chilies. It is usually prepared with chicken, lamb, or beef. A delicious Goan chicken curry that exudes an aromatic flavour. A great chicken dish to add on a dinner party menu, serve with any Indian bread or with some cooked rice",
        note:"Can be also made with Chickpeas and Mushroom for Vegetarians"
    },
    {   id:22,
        name:"Chicken Vindaloo",
        price:"16",
        mealQuantity: "24oz",
        description:"Popular Portuguese influenced indian/ goan dish made by marinating/ simmering meat in vindaloo paste.Based on the Portuguese dish carne de vinha d'alhos."
        
    }, 
  
    {   id:23,
        name:"Dry Shrimp Coconut Curry With Okra",
        price:"16",
        mealQuantity: "24oz",
        description:"A Coconut based curry with dry shrimps and okra. Mango can be substituted on request."
    },
    {   id:24,
        name:"Xec Xec",
        price:"16",
        mealQuantity: "24oz",
        description:"It is made by roasting spices, coconuts, onions and ginger with crab in it, resulting in one of the most powerfull flavor of crab. "
    },
    {   id:25,
        name:"Fugaath (Cabbage)",
        price:"14",
        mealQuantity: "24oz",
        description:"A dry Goan Vegetable made with cabbage and few spices. Fugaath or fugad in goan language translates to dry vegetable cooked in curry leaves and coconut. It is style of cooking vegetables quickly by tempering it "
    },
    {   id:26,
        name:"Mango Curry",
        price:"16",
        mealQuantity: "24oz",
        description:"A lovely spicy coconut curry made with raw mango is a must try. It pairs amazing well with rice."
    },
    
    {
        id:27,
        name:"Goan Beef stew",
        mealQuantity: "24oz",
        price:"18",
        description:"Before the buffet system was introduced in Goa, food courses used to be served individually in plates. This was practiced especially during Catholic marriages. The method of service was known as servico volante wherein soup, a fish dish, and a meat dish and pulao were served consecutively. The meat dish generally comprised a stew. The stew is usually prepared with pork , beef , vegetables and macaroni.",
    },
    {
        id:28,
        name:"Channa Ross (Channa Ross) ",
        mealQuantity: "24oz",
        price:"15",
        description:"Channa ross or chanya ross is a white peas curry made by cooking white peas in spicy coconut-based curry, Usually paired with Puri or Pao"
    },
    {
        id:29,
        name:"Goan style Fish Or Prawn Curry",
        mealQuantity: "24oz",
        price:"18",
        description:"Coconut curry made using Fish Or Prawns with a variety of spices, and coconut milk available With Kingfish, Mackrel, Pompano and Pompfret"
    },
    {
        id:30,
        name:"Prawns Molho",
        mealQuantity: "250ml",
        price:"13",
        description:"Tangy Spicy Curry made using  a variety of spices and vinegar. "
    },
    {
        id:31,
        name:"Beef Tongue Roast",
        mealQuantity: "24oz",
        price:"22",
        description:"Goan Beef Tongue is a culinary gem that showcases the rich flavors of Goan cuisine."
    },
    {
        id:32,
        name:"Fish Chillie",
        mealQuantity: "24oz",
        price:"22",
        description:"Goan Fish Chillie is a delectable seafood dish that captures the essence of Goan flavors."
    },
    {
        id:32,
        name:"Meat Ball",
        mealQuantity: "24oz",
        price:"22",
        description:"Goan Meatball Curry, is a flavorful dish that beautifully combines the rich spices of Goan cuisine with tender, juicy meatballs."
    }


    
    
   
    
    

]




export default curries;