const Icurries = [

    {
        id:31,
        name:"Butter Chicken",
        price:"15",
        mealQuantity: "24oz",
        description:"Aromatic golden chicken pieces in an incredible creamy curry sauce that is different from any other indian curry"
    },

    
    {
        id:32,
        name:"Chicken Amritsari",
        price:"15",
        mealQuantity: "24oz",
        description:"It is a rich creamy North Indian Chicken dish"
    }
    , 
    
   
     {
        id:33,
        name:"Biryani",
        price:"15",
        mealQuantity: "24oz",
        description:"Try our Biryani.It is prepared differenly with cashew nuts,raisins,herbs and special basmati rice.Available in Chicken and Mutton"
    }
    ,
 
  
    // {   id:34,
    //     name:"Chicken Handi/Mutton Handi",
    //     price:"15",
    //     mealQuantity: "24oz",
    //     description:"This is a delicious recipe where the chicken is cooked in yoghurt and tomato and a little cream is added towards the end."
    // },
    // {   id:35,
    //     name:"Dal with Spinach",
    //     price:"15",
    //     mealQuantity: "24oz",
    //     description:"There is nothing more comforting than a bowl of hot dal palak served with rice, roti or bhakri. Made with minimal amount of spices, this spinach dal is super flavorful, tasty, low-calorie, fiber and protein rich that suits everyone"
    // }, 
  
    // {   id:36,
    //     name:"Chicken Kadai",
    //     price:"15",
    //     mealQuantity: "24oz",
    //     description:"Kadai is a spicy & flavourful dish made with chicken, onions, tomatoes, ginger & garlic."
    // },
    // {   id:37,
    //     name:"Chicken 65",
    //     price:"15",
    //     mealQuantity: "24oz",
    //     description:"A popular, spicy delicious boneless chicken Starter"
    // },
    
    // {   id:38,
    //     name:"Dal Makani",
    //     price:"15",
    //     mealQuantity: "24oz",
    //     description:"A creamy buttery dal cooked with green lentils, kidney beans and butter"
    // },

   
    
    

]




export default Icurries;