import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {useState, useEffect, useContext} from 'react'
import CartContext from '../Context/CartContext'
const Snacks = (props) => {
  const [show, setShow] = useState(false)
  const {cart, setCart} = useContext(CartContext)
 

  const showAlert = (item) =>{
    
    setShow(true)
    setTimeout(() => {
      
      setShow(false)
    }, 800)
    let id = item.split(',')[0]
    let foundItem = cart.findIndex(item=>item.id === id)
    let tempArray = cart
    if(foundItem !== -1){
      
     
      tempArray[foundItem] = {
        id:  cart[foundItem].id,  
        name : cart[foundItem].name,
        quantity: cart[foundItem].quantity+1,
        price: parseInt(cart[foundItem].price),
        mealQuantity:cart[foundItem].mealQuantity

      }
   
      
      setCart(tempArray)
      
      
    }
    else {
      let name = item.split(',')[1]
      let price = item.split(',')[2]
      let mealQuantity = item.split(',') [3]
      let temp = {
        id:  id,  
        name : name,
        quantity: 1,
        price: parseInt(price),
        mealQuantity: mealQuantity
      }
      props.onAddCart(temp)
     
    }
    
   
  }
  
  return (
    <>
    <Typography variant='h7'sx={{fontSize: {sm:"10px" ,md:"16px"}}}>    
  {show === true ? (<Alert severity="success" style={{display:"flex", position:"absolute", zIndex:"1", right:"20%"}}>
      
      {props.snack.name}  <strong>was added successfully</strong>
      </Alert>)
      : ""}
        
         
          <Box sx={{width:{xs:"80%", lg:"100%"},
          textAlign:{xs:"left", lg:"justify"},

          margin:"auto", wordWrap:"",
          
         
            fontSize: {sm:"10px" ,md:"13px"},
            }}>
               <b style={{fontSize:"16px", fontWeight:600}}> {props.snack.name} | {props.snack.price} </b> <br />  
           
            {props.snack.description}<b><br /><i>size</i>:{props.snack.mealQuantity}  </b>
          </Box>
          <Box sx={{width:{xs:"80%", lg:"60%"},margin:"auto", wordWrap:"break-word",color:"black",
          paddingBottom:"10px",
            fontSize: {sm:"10px" ,md:"20px"}}}>
            {props.snack.note}
          </Box>
            {props.name === "Gcurry" ?  props.id===6? <br />  : ""  :  "" }
          
           
      </Typography>
    
 </>
    

  );
};

export default Snacks;
